import React from 'react'
import { graphql } from 'gatsby'
import { Intro, Layout } from '@components'
import BlockWrapper from '@pageBuilder/wrapper'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const Page = ({ data, pageContext }) => {
  const { moduleArea, seoMetaTags, title, button, image } = data.page

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Intro
        breadcrumbs={pageContext?.breadcrumbs}
        title={title}
        button={button}
        image={image}
      />
      <BlockWrapper blocks={moduleArea} />
    </Layout>
  )
}

export const query = graphql`
  query pageById($id: String!) {
    page: datoCmsPage(id: { eq: $id }) {
      id
      path
      slug
      title
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "550", fit: "crop", w: "1600", q: 60 }
        )
        alt
      }
      button {
        ...Link
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      moduleArea {
        ... on DatoCmsAccordion {
          ...Accordion
        }
        ... on DatoCmsVideoBanner {
          ...BannerVideo
        }
        ... on DatoCmsButton {
          ...Button
        }
        ... on DatoCmsCardGrid {
          ...CardGrid
        }
        ... on DatoCmsCardSlider {
          ...CardSlider
        }
        ... on DatoCmsContent {
          ...Content
        }
        ... on DatoCmsDonate {
          ...Donate
        }
        ... on DatoCmsFilteredBlogList {
          ...FilteredBlogList
        }
        ... on DatoCmsForm {
          ...Form
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsImageGrid {
          ...ImageGrid
        }
        ... on DatoCmsSupportersGrid {
          ...SupportersGrid
        }
        ... on DatoCmsPeopleGrid {
          ...PeopleGrid
        }
        ... on DatoCmsTimeline {
          ...Timeline
        }
        ... on DatoCmsImageWithContent {
          ...ImageWithContent
        }
        ... on DatoCmsTicker {
          ...Ticker
        }
      }
    }
  }
`
export default Page
